var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cyui-layout-wrapper"
  }, [_c('cyui-layout', {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_vm.isCustomHeader ? _c('cyui-header', {
          attrs: {
            "title": _vm.routeMetaTitle
          },
          on: {
            "go-back": _vm.goBack
          }
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "default",
      fn: function () {
        return [_c('div', {
          staticClass: "page"
        }, [_c('div', {
          staticClass: "list"
        }, _vm._l(_vm.productList, function (item, index) {
          return _c('div', {
            key: index,
            staticClass: "item"
          }, [_c('div', {
            staticClass: "title"
          }, [_c('span', [_vm._v("申请明细：第" + _vm._s(index + 1) + "条")])]), _c('van-cell-group', {
            staticClass: "cell-group"
          }, [_c('van-cell', {
            attrs: {
              "title": "资产属性",
              "value": _vm.productPropertyMap[item.productProperty] || '-'
            }
          }), _c('van-cell', {
            attrs: {
              "title": "资产名称",
              "value": item.productName || '-'
            }
          }), _c('van-cell', {
            attrs: {
              "title": "主规格",
              "value": item.standard || '-'
            }
          }), _c('van-cell', {
            attrs: {
              "title": "资产编号",
              "value": item.productNo || '-'
            }
          }), _c('van-cell', {
            attrs: {
              "title": "处置区域",
              "value": item.outSpaceName || '-'
            }
          }), _c('van-cell', {
            attrs: {
              "title": "处置价格/元",
              "value": item.disposePrice || '-'
            }
          }), _c('van-cell', {
            attrs: {
              "title": "单位",
              "value": item.unit || '-'
            }
          }), _c('van-cell', {
            attrs: {
              "title": "处置数量"
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function () {
                return [_c('van-stepper', {
                  attrs: {
                    "disabled": ""
                  },
                  model: {
                    value: item.disposeNum,
                    callback: function ($$v) {
                      _vm.$set(item, "disposeNum", $$v);
                    },
                    expression: "item.disposeNum"
                  }
                })];
              },
              proxy: true
            }], null, true)
          }, [_c('van-cell', {
            attrs: {
              "title": "处置原因",
              "value": _vm.disposeReasonMap[item.disposeReason] || '-'
            }
          })], 1)], 1)], 1);
        }), 0), _c('van-cell-group', {
          staticClass: "cell-group"
        }, [_c('van-cell', {
          attrs: {
            "title": "处置人",
            "value": _vm.disposePerson || '-'
          }
        }), _c('van-cell', {
          attrs: {
            "title": "处置总金额",
            "value": _vm.disposeTotalPrice || '-'
          }
        }), _c('van-field', {
          staticClass: "vtextarea",
          attrs: {
            "type": "textarea",
            "readonly": "",
            "label": "备注",
            "rows": "3",
            "autosize": "",
            "input-align": "left"
          },
          model: {
            value: _vm.remark,
            callback: function ($$v) {
              _vm.remark = $$v;
            },
            expression: "remark"
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };