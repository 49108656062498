import _keyBy from "lodash/keyBy";
import _mapValues from "lodash/mapValues";
import common from '@mixins/common';
import { mapGetters } from 'vuex';
import * as assetsApi from '@api/assets';
const productPropertyList = [{
  label: "易耗品",
  value: 1
}, {
  label: "低值品",
  value: 2
}, {
  label: "固定资产",
  value: 3
}];
const productPropertyMap = _mapValues(_keyBy(productPropertyList, i => i.value), j => j.label);
const disposeReasonList = [{
  label: "资产报废",
  value: 1
}, {
  label: "冲销处理",
  value: 2
}, {
  label: "闲置变卖",
  value: 3
}];
const disposeReasonMap = _mapValues(_keyBy(disposeReasonList, i => i.value), j => j.label);
export default {
  name: 'AssetsTakeLogDetail',
  mixins: [common],
  computed: {
    ...mapGetters('user', ['userInfo', 'projectInfo'])
  },
  data() {
    return {
      id: '',
      disposePerson: '',
      disposeTotalPrice: '',
      remark: '',
      productList: [],
      productPropertyMap,
      disposeReasonMap
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getData();
  },
  methods: {
    getData() {
      assetsApi.getDisposeDetail(this.id).then(res => {
        let item = res.data.data;
        this.disposePerson = item.disposePerson;
        this.disposeTotalPrice = item.disposeTotalPrice;
        this.projectId = item.projectId;
        this.projectName = item.projectName;
        this.remark = item.remark;
        this.productList = item.disposeDetailVos;
      });
    }
  }
};